import { createElement, useContext, createContext } from 'react'
import { track } from '@u/tracking'

const ExperienceContext = createContext()
const useExperiences = () => useContext(ExperienceContext)
const { Provider } = ExperienceContext

/**
 * Publish an experience action with reference to the flag and cohort.
 *
 * @param (function} Function used to track the event (probably via window.uSwitchEventTracking)
 * @param (string} flag - The flag name.
 * @param {number} cohort - The cohort the particular session received.
 */
const trackExperience = (
  track = () => console.log('No tracking function provided'),
  flag,
  cohort,
) => {
  const action = 'Experience Bucket'
  const label = `${flag}:${cohort}`

  track({ action, label })
}

export const ExperienceProvider = ({ children, value = {} }) => {
  Object.keys(value).forEach(flag => {
    trackExperience(track, flag, value[flag].cohort)
  })

  return createElement(Provider, { value }, children)
}

/**
 * A react component to make a decision on which experiment to render.
 *
 * @param {Object} props - The component properies.
 * @param {string} props.name = The name of the experiment flag.
 * @param {Variant} props.fallback - The fallback variation.
 * @param {Variant[]} props.variants - The list of variants in the experiment.
 */
export const ExperimentFlag = ({ name, fallback, variants }) => {
  const experiences = useExperiences()
  const experience = experiences[name]

  // If experience does not exist, show the last variant.
  if (!experience) {
    return createElement(fallback.component)
  }

  // If the experience exists, but the cohort does not, show the default experience.
  const variant = variants.find(v => v.cohort === String(experience.cohort))
  if (!variant) {
    return createElement(fallback.component)
  }

  return createElement(variant.component, { experienceValue: experience.value })
}
