export const isObject = value =>
  typeof value === 'object' && value !== null && !Array.isArray(value)

export const pick = (arr, obj) =>
  arr.reduce((acc, it) => (it in obj ? { ...acc, [it]: obj[it] } : acc), {})

export const omit = (arr, obj) =>
  Object.entries(obj)
    .filter(([a, b]) => !arr.includes(a))
    .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})

export const path = (arr, obj, def = undefined) =>
  arr.reduce((acc, it) => (acc !== def && {}.hasOwnProperty.call(acc, it) ? acc[it] : def), obj)

export const isEmpty = val => val === null || !(Object.keys(val) || val).length

/**
 * Map over an objects entries
 *
 * @param {Function} f - Function to apply to tuples of Key/Values
 * @param {Object} obj - The object to map over
 */
export const mapObj = (f = i => i, obj) =>
  Object.entries(obj).reduce((acc, [key, val]) => ({ ...acc, [key]: f(val) }), {})

/**
 * Chunks an array into smaller arrays of a specified size.
 *
 * Use Array.from() to create a new array, that fits the number of
 * chunks that will be produced. Use Array.prototype.slice() to map
 * each element of the new array to a chunk the length of size. If the
 * original array can't be split evenly, the final chunk will contain
 * the remaining elements.
 *
 * @param {Array} arr - List of items to chunk
 * @param {number} size - The size of each chunk
 * @returns {Array}
 */
export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  )

export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)))
