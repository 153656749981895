import { mapObj, compose } from '@u/obj'

const sys = (s = {}) => s.sys || {}

const getFields = ({ fields = {} }) => fields

export const getAuthorName = (model = {}) => getFields(model).name || null
export const articleSchema = (page = {}) => getFields(page).articleSchema || null
export const contentId = (entity = {}) => sys(entity).id
export const spaceId = (entity = {}) => sys(entity).spaceId || sys(entity).space?.sys?.id
export const contentTypeId = (entity = {}) =>
  sys(entity)?.contentTypeId || sys(entity)?.contentType?.sys?.id

const sysId = contentId

export const pageTitle = (page = {}) => getFields(page).title || ''
export const createdAt = (entity = {}) => sys(entity)?.createdAt
export const updatedAt = (entity = {}) => sys(entity)?.updatedAt

const contentfulEnv = process.env.CONTENTFUL_ENV || ''

export const dataProps = (entity = {}) => ({
  'data-content-type': contentTypeId(entity),
  'data-entity-id': contentId(entity),
  'data-space-id': spaceId(entity),
  'data-entity-name': entity.name || '',
  'data-entity-variant': entity.variant || '',
  'data-entity-layout': entity.layout || '',
  'data-environment': contentfulEnv,
})

export const findInclude = (includes, id) =>
  Object.values(includes).find(it => contentTypeId(it) === id)

export const findIncludes = (includes, ids) =>
  Object.values(includes).filter(it => [].concat(ids).indexOf(contentTypeId(it)) > -1)

/* Simpify entities */
const simplifyEntitySys = sys => ({
  id: sys?.id,
  spaceId: sys?.space?.sys?.id,
  contentTypeId: sys?.contentType?.sys?.id,
  total: sys?.total,
})

const simplifyEntrySys = entry => ({ sys: { id: entry?.sys?.id } })

const simplifyEntityFields = fields =>
  Array.isArray(fields.entries)
    ? { ...fields, entries: fields.entries.map(simplifyEntrySys) }
    : fields

const simplifyEntity = ({ fields, sys }) => ({
  fields: simplifyEntityFields(fields),
  sys: simplifyEntitySys(sys),
})

const simplifyEntities = ({ includes, items, ...rest }) => ({
  ...rest,
  items: items.map(simplifyEntity),
  includes: mapObj(simplifyEntity, includes),
})

/* Simplify Product dataset */
const simplifyProductDatasets = ({ includes, ...rest }) => {
  const productTables = Object.values(includes).filter(it => contentTypeId(it) === 'productTable')

  const productDatasets = productTables
    .map(({ fields }) => [fields, includes[sysId(fields.dataset)]])
    .reduce((acc, [{ rowCount, filterIds }, entity]) => {
      if (entity?.fields?.query) return entity
      const data = entity.fields.data
        .filter(({ id }) => (!filterIds ? true : filterIds.includes(id)))
        .slice(0, rowCount)

      return {
        ...acc,
        [sysId(entity)]: {
          ...entity,
          fields: { ...entity.fields, data },
        },
      }
    }, {})

  return { ...rest, includes: { ...includes, ...productDatasets } }
}

export const simplifyCtx = compose(
  simplifyProductDatasets,
  simplifyEntities,
)
