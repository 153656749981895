export const app = (s = {}) => s.app || {}

export const env = (s = {}) => app(s).env || 'development'

export const theme = (s = {}) => app(s).theme || {}

export const router = (s = {}) => app(s).router || {}

export const meta = (s = {}) => app(s).meta || {}

export const partialHydration = (s = {}) => meta(s).partialHydration || true

export const iconsPath = (s = {}) => meta(s)?.iconsPath

export const paginationLimit = (s = {}) => meta(s)?.paginationLimit || 12

export const location = (s = {}) => app(s).location || {}

export const query = (s = {}) => location(s).query || {}

export const paginationPage = (s = {}) => query(s).page || 0

export const pathname = (s = {}) => location(s).pathname || ''

export const origin = (s = {}) => location(s).origin || ''

export const host = (s = {}) => location(s).host || ''

export const pagination = (s = {}, o = {}) => ({
  limit: o.limit || paginationLimit(s),
  page: paginationPage(s),
  pathname: pathname(s),
  origin: origin(s),
})
