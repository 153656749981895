import React from 'react'
import { useSelector } from 'react-redux'

import { partialHydration } from '@r/app-selectors'

const DontHydrate = props => {
  const usePartialHydration = useSelector(partialHydration)

  if (!usePartialHydration) return props.children
  return (
    <Choose>
      <When condition={typeof window === 'undefined' || window.__force_hydrate__}>
        <span className={props.className}>{props.children}</span>
      </When>
      <Otherwise>
        <span
          className={props.className}
          dangerouslySetInnerHTML={{ __html: '' }}
          suppressHydrationWarning
        />
      </Otherwise>
    </Choose>
  )
}

export default DontHydrate
