import sw from '@u/switch'
import * as act from '@r/elasticsearch-actions'
import { combineFilters } from '@u/elasticsearch'

export default (state = {}, { type, payload }) =>
  sw({
    [act.SET_DATA]: data => ({ ...state, data }),
    [act.SET_BASE_SORTS]: baseSorts => ({
      ...state,
      sorts: { ...state.sorts, baseSorts },
    }),
    [act.SET_SELECTED_SORT]: selectedSort => ({
      ...state,
      sorts: { ...state.sorts, selectedSort },
    }),
    [act.SET_FILTERS]: selectedFilters => ({
      ...state,
      filters: {
        ...state.filters,
        selectedFilters: combineFilters(state.filters?.selectedFilters, selectedFilters),
      },
    }),
    default: state,
  })(type, payload)
