const flat = arr => arr.reduce((item, val) => item.concat(val), [])

const filter = arr => arr.filter(item => item && item.length > 0)

const sanitize = arr => filter(flat(arr))

const prefix = ({ prefix = '' }) => prefix

export const joinPaths = (prefix, suffix) =>
  sanitize([prefix, suffix])
    .join('/')
    .trim()
    .replace(/\/{2,}/g, '/')

export const prefixPath = (ctx = {}, path) => joinPaths(prefix(ctx), path)

export const pathVariant = path =>
  path.endsWith('/') ? path.slice(0, path.length - 1) : path.concat('/')
