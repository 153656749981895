import { composeWithDevTools } from 'redux-devtools-extension'
import { connect, Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { HelmetProvider } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'theme-ui'
import loadable from '@loadable/component'
import React from 'react'
import thunk from 'redux-thunk'

import DontHydrate from '@h/dont-hydrate'

import { ExperienceProvider } from '@h/experiences'
import { router, theme } from '@r/app-selectors'
import { prefixPath } from '@u/paths'
import reducer from '@r/reducer'
import { bbDealsFooterData } from './utils/bb-deals-footer-data'
import Global from '@u/global-styles'

const Main = loadable(() => import('@p/main'))
const Footer = loadable(() => import('@c/footer'))
const Header = loadable(() => import('@c/header'))

const BBDealsHeader = loadable(() => import('eevee-components/bbdeals-header'))
const BBDealsFooter = loadable(() => import('eevee-components/footer'))

const ConnectedThemeProvider = connect(s => ({
  theme: theme(s),
}))(ThemeProvider)

export default ({ isServer, initialState, helmetContext = {}, experiences }) => {
  const state = isServer ? initialState : window.__initialState__
  const experienceContext = isServer ? experiences : window.__experiences__

  const store = createStore(reducer, state, composeWithDevTools(applyMiddleware(thunk)))
  const { core } = router(state)

  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <ExperienceProvider value={experienceContext}>
          <ConnectedThemeProvider theme={theme(state)}>
            <Global />
            <Switch>
              <Route
                exact
                path={prefixPath(core, '/footer/bbdeals.html')}
                render={props => <BBDealsFooter id={'bbdeals'} data={bbDealsFooterData} />}
              />
              <Route
                exact
                path={prefixPath(core, '/header/bbdeals.html')}
                render={props => (
                  <DontHydrate>
                    <BBDealsHeader />
                  </DontHydrate>
                )}
              />
              <Route exact path={prefixPath(core, '/footer.html')} render={props => <Footer />} />
              <Route exact path={prefixPath(core, '/header.html')} render={props => <Header />} />
              <Route path={prefixPath(core, '/*')} exact render={props => <Main {...props} />} />
            </Switch>
          </ConnectedThemeProvider>
        </ExperienceProvider>
      </Provider>
    </HelmetProvider>
  )
}
