import React from 'react'
import { Global as EmotionGlobal, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

const Global = () => (
  <EmotionGlobal
    styles={css`
      ${emotionNormalize}

      .uconsent-container {
        width: 100%;
        padding-bottom: 16px;
        margin: 0 24px;
        overflow: hidden;
        transition: padding-bottom 0.5s, max-height 0.5s;
      }

      .email .uconsent-container {
        padding-bottom: 16px;
        max-height: 94px;
        @media (max-width: 320px) {
          max-height: 114px;
        }
      }
    `}
  />
)

export default Global
