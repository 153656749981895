export const SET_BREADCRUMB = 'SET_BREADCRUMB'
export const SET_CTX = 'SET_CTX'
export const SET_URL = 'SET_URL'
export const SET_PAGE_DATES = 'SET_PAGE_DATES'
export const SIMPLIFY_CTX = 'SIMPLIFY_CTX'
export const SIMPLIFY_PRODUCT_DATASET = 'SIMPLIFY_PRODUCT_DATASET'

export const setBreadcrumb = payload => ({ type: SET_BREADCRUMB, payload })
export const setCtx = payload => ({ type: SET_CTX, payload })
export const setUrl = payload => ({ type: SET_URL, payload })
export const setPageDates = payload => ({ type: SET_PAGE_DATES, payload })
export const simplifyCtx = _ => ({ type: SIMPLIFY_CTX })
export const simplifyProductDataset = _ => ({ type: SIMPLIFY_PRODUCT_DATASET })
