import 'url-polyfill'
import 'core-js/fn/object'
import 'core-js/fn/array/includes'
import 'core-js/fn/string/includes'
import 'core-js/fn/string/starts-with'
import 'core-js/fn/string/ends-with'
import 'core-js/fn/array/find'
import 'core-js/fn/array/find-index'
import 'core-js/fn/array/from'
import 'core-js/fn/array/fill'
import 'core-js/fn/promise'

import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/core'
import 'lazysizes'

import App from './app'

const cache = createCache()

loadableReady(() => {
  hydrate(
    <CacheProvider value={cache}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CacheProvider>,
    document.getElementById('app'),
  )
})

if (module.hot) {
  module.hot.accept()
}

console.log('client-with-js')
