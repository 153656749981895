import getWindow from '@u/get-window'
import { isEmpty } from '@u/obj'
import { appName } from '../../__config__/env.json'

/**
 * Only log the GA event on the client.
 *
 * @param {Object} data - The event data to log.
 */
const logTrackEvent = data => {
  if (!isEmpty(getWindow())) console.log('gaEvent', data)
}

export const track = ({
  action,
  label,
  category = appName,
  value,
  callback,
  interactive = false,
}) =>
  getWindow().uSwitchEventTracking
    ? getWindow().uSwitchEventTracking(category, action, label, value, callback, !interactive)
    : logTrackEvent({ type: appName, action, label, value, callback, nonInteractive: !interactive })

/**
 * @typedef {Object<string, string>} DataAttributes
 */

/**
 * parseTrackingUrl
 *
 * This function a fully qualified URL and returns a tuple of a
 * trimmed href & a function to track it's call. This trimmed href
 * will not include the action, label, & category values
 *
 * @example
 *  const [trimmedHref, trackClick] = parseTrackingUrl('https://domain.com/#action=click&label=href')
 *
 * @param {string} href - A href string
 * @returns {[string, DataAttributes]}
 */

export const parseTrackingUrl = href => {
  try {
    const identity = {}
    const { hash } = new URL(
      /^\//.test(href)
        ? `https://stub.domain${href}` // Stub out the domain for relative paths
        : href, // Path is fully qualified so good to go
    )

    if (!hash) return [href, identity]

    const { action, label, category, ...rest } = hash
      .slice(1)
      .split('&')
      .reduce((acc, it) => {
        const [k, v] = it.split('=')
        return { ...acc, [k]: v }
      }, {})

    const trimmedSearch = Object.entries(rest)
      .reduce((acc, [key, val]) => {
        return val ? acc.concat(`${key}=${val}`) : acc.concat(key)
      }, [])
      .join('&')

    const trimmedSearchString = trimmedSearch.length ? `#${trimmedSearch}` : ''

    const trimmedHref = href.replace(hash, trimmedSearchString)
    const trackFunction = action
      ? {
          'data-action': action,
          'data-label': label || href,
          'data-category': category,
          'data-trackable': true,
        } // Data attributes
      : identity // Identity to do nothing

    return [trimmedHref, trackFunction]
  } catch (e) {
    return [href, i => i]
  }
}
