export const SET_DATA = 'SET_DATA'
export const SET_BASE_SORTS = 'SET_BASE_SORTS'
export const SET_SELECTED_SORT = 'SET_SELECTED_SORT'
export const SET_FILTERS = 'SET_FILTERS'

export const setData = payload => ({ type: SET_DATA, payload })

export const setBaseSorts = payload => ({ type: SET_BASE_SORTS, payload })

export const setSelectedSort = payload => ({ type: SET_SELECTED_SORT, payload })

export const setFilters = payload => ({ type: SET_FILTERS, payload })
