import { set } from '@u/deep-find'
import get from '@uswitch/trustyle-utils.get'

export const combineFilters = (currentFilters = {}, newFilters) =>
  newFilters.reduce(
    (acc, { label, options }) => ({
      ...acc,
      [label]: { label, options },
    }),
    currentFilters.selectedFilters,
  )

export const dataCleanser = (keyPaths, data) =>
  data.map(hit => {
    let result = {}
    keyPaths.reduce((acc, path) => {
      path = path.split('.')
      const val = get(hit._source, path)
      set(acc, path, val)
      result = {
        _source: acc,
      }
      return acc
    }, {})
    return result
  })
