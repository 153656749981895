export const bbDealsFooterData = {
  primary: {
    subtitle: '© 2020',
    links: [
      { href: '/privacy-policy', title: 'Privacy Policy' },
      { href: '/terms-of-use', title: 'Terms of Use' },
      { href: '/privacy-policy/#cookies', title: 'Cookie Policy' },
      { href: '/slavery-statement', title: 'Slavery Statement' },
      { href: '/contact-us', title: 'Contact Us' },
      { href: '/complaints', title: 'Complaints' },
    ],
  },
  secondary: [
    {
      title: 'Providers',
      links: [
        { href: '/providers/bt-broadband-deals/', title: 'BT' },
        { href: '/providers/ee-broadband-deals/', title: 'EE' },
        { href: '/providers/now-tv-broadband-deals/', title: 'NOW TV' },
        { href: '/providers/plusnet-broadband-deals/', title: 'Plusnet' },
        { href: '/providers/sky-broadband-deals/', title: 'Sky' },
        { href: '/providers/talktalk-broadband-deals/', title: 'TalkTalk' },
        { href: '/providers/virgin-broadband-deals/', title: 'Virgin Media' },
        { href: '/providers/vodafone-broadband-deals/', title: 'Vodafone' },
      ],
    },
    {
      title: 'Reviews',
      links: [
        { href: '/reviews/bt-broadband-review/', title: 'BT' },
        { href: '/reviews/ee-broadband-review/', title: 'EE' },
        { href: '/reviews/now-tv-broadband-review/', title: 'NOW TV' },
        { href: '/reviews/plusnet-broadband-review/', title: 'Plusnet' },
        { href: '/reviews/sky-broadband-review/', title: 'Sky' },
        { href: '/reviews/talktalk-broadband-review/', title: 'TalkTalk' },
        { href: '/reviews/virgin-broadband-review/', title: 'Virgin Media' },
        { href: '/reviews/vodafone-broadband-review/', title: 'Vodafone' },
      ],
    },
    {
      title: 'Guides',
      links: [
        { href: '/guides/fibre-in-my-area/', title: 'Can I get fibre broadband in my area?' },
        { href: '/guides/what-is-mifi-dongle/', title: 'What is a MiFi dongle?' },
        { href: '/guides/bits-and-bytes-explained/', title: 'Bits and bytes explained' },
      ],
    },
  ],
  legalese: {
    text: [
      '© 2020 Broadbanddeals and its licensors. All rights reserved. Broadbanddeals is a trading name of Uswitch Limited, registered in England and Wales (company number 03612689). Our registered address is The Cooperage, 5 Copper Row, London, SE1 2LH.',
      'Broadbanddeals services are provided at no cost to you, but we may receive a commission from the suppliers to which we refer you.',
    ],
  },
}
