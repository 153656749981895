import { combineReducers } from 'redux'
import app from '@r/app-reducer'
import contentful from '@r/contentful-reducer'
import elasticsearch from '@r/elasticsearch-reducer'

export default combineReducers({
  app,
  contentful,
  elasticsearch,
})
