import sw from '@u/switch'
import * as act from '@r/app-actions'

export default (state = {}, { type, payload }) =>
  sw({
    [act.SET_ENV]: env => ({ env, ...state }),
    [act.SET_THEME]: theme => ({ ...state, theme }),
    [act.SET_META_INFO]: meta => ({ meta, ...state }),
    [act.SET_ROUTER]: router => ({ router, ...state }),
    [act.SET_LOCATION]: location => ({ ...state, location }),
    [act.SET_DATES]: dates => ({ ...state, dates }),
    default: state,
  })(type, payload)
