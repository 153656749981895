import sw from '@u/switch'
import * as act from '@r/contentful-actions'

import { simplifyCtx } from '@u/contentful'

export default (state = {}, { type, payload }) =>
  sw({
    [act.SET_BREADCRUMB]: breadcrumb => ({ ...state, breadcrumb }),
    [act.SET_PAGE_DATES]: dates => ({ ...state, dates }),
    [act.SET_CTX]: ctx => ({ ...state, ctx }),
    [act.SET_URL]: ({ href, canonical }) => ({
      url: { href, canonical },
      ...state,
    }),

    [act.SIMPLIFY_CTX]: _ => ({ ...state, ctx: simplifyCtx(state.ctx) }),
    default: state,
  })(type, payload)
