export const SET_ENV = 'SET_ENV'
export const SET_THEME = 'SET_THEME'
export const SET_META_INFO = 'SET_META_INFO'
export const SET_ROUTER = 'SET_ROUTER'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_DATES = 'SET_DATES'

export const setEnv = env => ({ type: SET_ENV, payload: env })
export const setTheme = payload => ({ type: SET_THEME, payload })
export const setMeta = payload => ({ type: SET_META_INFO, payload })
export const setRouter = payload => ({ type: SET_ROUTER, payload })
export const setLocation = payload => ({ type: SET_LOCATION, payload })
export const setDates = payload => ({ type: SET_DATES, payload })
